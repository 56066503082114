import Vue from "vue";
import VueRouter from "vue-router";
import UsersView from "../views/user/UsersView";
import { navigation } from "./types";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "*",
    component: UsersView,
    meta: navigation.users.meta,
  },
  {
    path: navigation.login.path,
    name: navigation.login.name,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/LoginView.vue"),
  },
  {
    path: navigation.users.path,
    name: navigation.users.name,
    meta: navigation.users.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/UsersView.vue"),
  },
  {
    path: navigation.user.path,
    name: navigation.user.name,
    meta: navigation.user.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/UserView.vue"),
  },
  {
    path: navigation.roles.path,
    name: navigation.roles.name,
    meta: navigation.roles.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/role/RolesView.vue"),
  },
  {
    path: navigation.orders.path,
    name: navigation.orders.name,
    meta: navigation.orders.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/order/OrdersView.vue"),
  },
  {
    path: navigation.offers.path,
    name: navigation.offers.name,
    meta: navigation.offers.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/offer/OffersView.vue"),
  },
  {
    path: navigation.withdraws.path,
    name: navigation.withdraws.name,
    meta: navigation.withdraws.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/withdraw/WithdrawsView.vue"
      ),
  },
  {
    path: navigation.disputes.path,
    name: navigation.disputes.name,
    meta: navigation.disputes.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dispute/DisputesView.vue"
      ),
  },
  {
    path: navigation.dispute.path,
    name: navigation.dispute.name,
    meta: navigation.dispute.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dispute/DisputeView.vue"
      ),
  },
  {
    path: navigation.pays.path,
    name: navigation.pays.name,
    meta: navigation.pays.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pay/PaysView.vue"),
  },
  {
    path: navigation.fees.path,
    name: navigation.fees.name,
    meta: navigation.fees.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/fee/FeesView.vue"),
  },
  {
    path: navigation.logs.path,
    name: navigation.logs.name,
    meta: navigation.logs.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/log/LogsView.vue"),
  },
  {
    path: navigation.verifications.path,
    name: navigation.verifications.name,
    meta: navigation.verifications.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/verification/VerificationsView.vue"
      ),
  },
  {
    path: navigation.wallets.path,
    name: navigation.wallets.name,
    meta: navigation.wallets.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wallet/WalletsView.vue"),
  },
  {
    path: navigation.tokens.path,
    name: navigation.tokens.name,
    meta: navigation.tokens.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/token/TokensView.vue"),
  },
  {
    path: navigation.token.create.path,
    name: navigation.token.create.name,
    meta: navigation.token.create.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/token/TokenView.vue"),
  },
  {
    path: navigation.token.edit.path,
    name: navigation.token.edit.name,
    meta: navigation.token.edit.meta,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/token/TokenView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
