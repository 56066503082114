import { RootActionTypes, RootMutationTypes } from "./types";

const actions = {
  [RootActionTypes.SetNotification](
    { commit },
    { status = "Unknown", message = "Oops some thing went wrong" } = {}
  ) {
    commit(RootMutationTypes.SetNotification, { status, message });
    if (status !== 200) {
      throw { status, message };
    }
  },

  [RootActionTypes.RemoveNotification]({ commit }, index) {
    commit(RootMutationTypes.RemoveNotification, index);
  },
};

export default actions;
