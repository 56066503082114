const AuthNamespace = "auth";

const AuthActionTypes = {
  Login: "login",
  Logout: "logout",
};

const AuthMutationTypes = {
  SetUser: "SetUser",
  SetToken: "SetToken",
  SetLoadingState: "SetLoadingState",
};

export { AuthNamespace, AuthActionTypes, AuthMutationTypes };
