import { resolve } from "path";

// wid - withdraw id

const Withdraw = ({ basePath = "withdraw_tickets", instance = {} } = {}) => ({
  async getCollection({ path = "index", page = 1, per_page = 10 } = {}) {
    return await instance.get(resolve(basePath, path), {
      params: { page, per_page },
    });
  },

  async confirm({ path = "confirm", wid = "" } = {}) {
    return await instance.post(resolve(basePath, path, wid));
  },

  async decline({ path = "decline", wid = "", decline_reason = "" } = {}) {
    return await instance.post(resolve(basePath, path, wid), {
      decline_reason,
    });
  },
});

export default Withdraw;
