<template>
  <b-modal
    v-bind="{
      visible,
      centered,
      hideFooter,
      hideHeaderClose,
      title,
      ...style,
    }"
    v-on="{
      change: (visible) => onChange(visible),
      hide: () => onClose({ confirm: false }),
    }"
  >
    <template slot="default">
      <validation-observer v-slot="{ valid }">
        <b-form>
          <slot name="default"> </slot>
          <slot name="action">
            <b-btn
              class="rounded"
              v-bind="{
                variant: style.cancelVariant,
                block: true,
              }"
              v-on="{
                click: () => onClose({ confirm: false }),
              }"
            >
              <template v-if="loading">
                <b-spinner
                  v-bind="{
                    small: true,
                  }"
                >
                </b-spinner>
              </template>
              <template v-else>
                <span class="text-capitalize h5"> cancel </span>
              </template>
            </b-btn>
            <b-btn
              class="rounded"
              v-bind="{
                disabled: !valid,
                variant: style.okVariant,
                block: true,
              }"
              v-on="{
                click: () => onClose({ confirm: true }),
              }"
            >
              <template v-if="loading">
                <b-spinner
                  v-bind="{
                    small: true,
                  }"
                >
                </b-spinner>
              </template>
              <template v-else>
                <span class="text-capitalize h5"> confirm </span>
              </template>
            </b-btn>
          </slot>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";

export default BModal.extend({
  name: "BaseModal",

  components: {
    ValidationObserver,
  },

  model: {
    prop: "visible",
    event: "change",
  },

  props: {
    title: {
      type: String,
      require: true,
    },
    centered: {
      type: Boolean,
      default() {
        return true;
      },
    },
    theme: {
      type: String,
      default() {
        return "dark";
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hideFooter: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hideHeaderClose: {
      type: Boolean,
      default() {
        return true;
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  computed: {
    style() {
      return this.theme === "dark"
        ? {
            headerTextVariant: "white",
            headerBgVariant: "dark",
            bodyBgVariant: "dark",
            bodyTextVariant: "white",
            footerBgVariant: "dark",
            okVariant: "warning",
            cancelVariant: "primary",
          }
        : {
            headerTextVariant: "black",
            headerBgVariant: "white",
            bodyBgVariant: "white",
            bodyTextVariant: "black",
            footerBgVariant: "white",
            okVariant: "warning",
            cancelVariant: "primary",
          };
    },
  },

  methods: {
    onChange(visible) {
      this.$emit("change", visible);
    },
    onClose({ confirm }) {
      this.onChange(false);

      this.$emit("close", {
        confirm,
      });
    },
  },
});
</script>

<style lang="scss">
.modal-dialog {
  border-width: 0;

  .modal-header {
    border-width: 0;
  }

  .modal-content {
    border-width: 0;
  }

  .modal-footer {
    border-width: 0;
  }
}
</style>
