var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('b-form-group',_vm._b({staticClass:"text-capitalize"},'b-form-group',{
      label: _vm.label,
    },false),[_c('b-input-group',{staticClass:"border rounded"},[_c('b-form-input',_vm._g(_vm._b({},'b-form-input',{
          value: _vm.value,
          autocomplete: _vm.autocomplete,
          placeholder: _vm.placeholder,
          type: _vm.type,
          readonly: _vm.readonly,
        },false),{
          input: function (value) { return _vm.onInput({ validate: validate, value: value }); },
        })),_c('b-input-group-append',_vm._g(_vm._b({staticClass:"cursor-pointer",class:{
          'd-none': !_vm.icon,
        }},'b-input-group-append',{
          isText: true,
        },false),{
          click: _vm.onIcon,
        }),[_c('b-icon',_vm._b({},'b-icon',{
            icon: _vm.icon,
          },false))],1)],1),_c('b-form-text',{style:({
        minHeight: '1.5em',
      })},[_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])])],1)]}}])},'validation-provider',{
    rules: _vm.rules,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }