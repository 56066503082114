import state from "@/store/auth/state";
import actions from "@/store/auth/actions";
import mutations from "@/store/auth/mutations";

const auth = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default auth;
