const state = {
  sells: [],
  sell: {},
  loading: false,
  currencies: [],
  page: 1,
  per_page: 10,
  total: 0,
};

export default state;
