var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{style:({
    position: 'sticky',
    zIndex: 2,
    top: 0,
  })},[_c('b-navbar',_vm._b({},'b-navbar',{
      type: _vm.type,
      variant: _vm.variant,
    },false),[_c('b-navbar-nav',{staticClass:"mr-2"},[_c('b-icon',_vm._g(_vm._b({staticClass:"cursor-pointer"},'b-icon',{
          color: 'white',
          fontScale: 2,
          icon: 'b-icon-list',
        },false),{
          click: _vm.onMenu,
        }))],1),_c('b-navbar-brand',[_vm._v("Altcoins Hub")]),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-btn',_vm._g(_vm._b({},'b-btn',{ variant: _vm.variant, pill: true },false),{
          click: _vm.onLogout,
        }),[_c('b-icon',_vm._b({},'b-icon',{
            icon: 'b-icon-box-arrow-in-right',
          },false))],1)],1)],1),_c('b-progress',_vm._b({},'b-progress',{
      value: _vm.loading ? 0 : 100,
      variant: 'warning',
      height: '1px',
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }