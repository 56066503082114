var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',_vm._b({},'b-card',{
    noBody: true,
    bgVariant: 'primary',
    textVariant: 'white',
  },false),[_c('b-row',_vm._b({staticClass:"flex-grow-2"},'b-row',{
      noGutters: true,
    },false),[_c('b-col',[_c('main-table',_vm._b({},'main-table',{
          loading: _vm.loading,
          items: _vm.items,
          page: _vm.page,
          perPage: _vm.perPage,
          totalRows: _vm.totalRows,
        },false))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }