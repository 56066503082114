import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const root = {
  state,
  actions,
  mutations,
};

export default root;
