<template>
  <b-card-text
    class="d-flex align-items-center text-white cursor-pointer"
    v-b-tooltip.bottom.hover
    v-bind="{
      variant: 'transplant',
      title: text,
    }"
    v-on="{
      click: () => onClipboardCopy(text),
    }"
  >
    <b-icon
      class="mr-2"
      v-bind="{
        icon: 'b-icon-files',
      }"
    >
    </b-icon>
    <span>
      {{ truncate(value ? value : text) }}
    </span>
  </b-card-text>
</template>

<script>
import { BIcon, BIconFiles } from "bootstrap-vue";

export default {
  name: "BaseClipboardCopy",
  components: {
    BIcon,
    // eslint-disable-next-line vue/no-unused-components
    BIconFiles,
  },
  props: {
    text: {
      type: String,
      default() {
        return "";
      },
    },
    value: {
      type: String,
      default() {
        return "";
      },
    },
    limit: {
      type: Number,
      default() {
        return 6;
      },
    },
    cut: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  methods: {
    async onClipboardCopy(value) {
      try {
        await navigator.clipboard.writeText(value);
      } catch (error) {
        throw new Error(error);
      }
    },
    truncate(text, ellipse = "...") {
      return this.cut
        ? `${text.slice(0, this.limit)}${ellipse}${text.slice(-this.limit)}`
        : text;
    },
  },
};
</script>
