var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-text',_vm._g(_vm._b({directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom.hover",modifiers:{"bottom":true,"hover":true}}],staticClass:"d-flex align-items-center text-white cursor-pointer"},'b-card-text',{
    variant: 'transplant',
    title: _vm.text,
  },false),{
    click: function () { return _vm.onClipboardCopy(_vm.text); },
  }),[_c('b-icon',_vm._b({staticClass:"mr-2"},'b-icon',{
      icon: 'b-icon-files',
    },false)),_c('span',[_vm._v(" "+_vm._s(_vm.truncate(_vm.value ? _vm.value : _vm.text))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }