<template>
  <validation-provider
    v-slot="{ validate, errors }"
    v-bind="{
      rules,
    }"
  >
    <b-form-group
      class="text-capitalize"
      v-bind="{
        label,
      }"
    >
      <b-input-group class="border rounded">
        <b-form-input
          v-bind="{
            value,
            autocomplete,
            placeholder,
            type,
            readonly,
          }"
          v-on="{
            input: (value) => onInput({ validate, value }),
          }"
        >
        </b-form-input>

        <b-input-group-append
          class="cursor-pointer"
          v-bind:class="{
            'd-none': !icon,
          }"
          v-bind="{
            isText: true,
          }"
          v-on="{
            click: onIcon,
          }"
        >
          <b-icon
            v-bind="{
              icon,
            }"
          >
          </b-icon>
        </b-input-group-append>
      </b-input-group>

      <b-form-text
        v-bind:style="{
          minHeight: '1.5em',
        }"
      >
        <small class="text-danger">
          {{ errors[0] }}
        </small>
      </b-form-text>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BIcon,
  BIconEyeSlash,
  BIconEye,
  BIconArrowUpCircle,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";

export default {
  name: "BaseInput",

  components: {
    ValidationProvider,
    BIcon,
    // eslint-disable-next-line vue/no-unused-components
    BIconEyeSlash,
    // eslint-disable-next-line vue/no-unused-components
    BIconEye,
    // eslint-disable-next-line vue/no-unused-components
    BIconArrowUpCircle,
  },

  model: {
    prop: "value",
    event: "input",
  },

  props: {
    value: {
      type: [String, Number],
      default() {
        return "";
      },
    },
    label: {
      type: String,
      default() {
        return "";
      },
    },
    autocomplete: {
      type: String,
      default() {
        return "off";
      },
    },
    placeholder: {
      type: String,
      default() {
        return "";
      },
    },
    type: {
      type: String,
      default() {
        return "text";
      },
    },
    rules: {
      type: String,
      default() {
        return "";
      },
    },
    icon: {
      type: String,
      default() {
        return "";
      },
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  methods: {
    async onInput({ validate, value }) {
      await validate(value);
      this.$emit("input", value);
    },
    onIcon() {
      this.$emit("click:icon");
    },
  },
};
</script>

<style lang="scss">
.input-group {
  background-color: inherit;
  color: inherit;
  .custom-select,
  .form-control,
  .input-group-text {
    color: inherit;
    border-width: 0;
    background-color: inherit;

    &:focus {
      color: inherit;
      box-shadow: none;
      background-color: inherit;
    }
  }
}
</style>
