import { RootMutationTypes } from "./types";

const mutations = {
  [RootMutationTypes.SetNotification](
    state,
    { status = 400, message = "" } = {}
  ) {
    state.notifications.push({ status, message });
  },
  [RootMutationTypes.RemoveNotification](state, index) {
    state.notifications.splice(index, 1);
  },
};

export default mutations;
