const UserNamespace = "user";

const UserActionTypes = {
  GetUserCollection: "GetUserCollection",
  GetUser: "GetUser",
  CreateUser: "CreateUser",
  UpdateUser: "UpdateUser",
};

const UserMutationTypes = {
  SetUserCollection: "SetUserCollection",
  SetUser: "SetUser",
  SetLoadingState: "SetLoadingState ",
};

export { UserNamespace, UserActionTypes, UserMutationTypes };
