import { RootActionTypes } from "@/store/root/types";
import { FeeActionTypes, FeeMutationTypes } from "./types";
import { fee } from "@/service";

const actions = {
  async [FeeActionTypes.GetFeeCollection](
    { dispatch, commit },
    { page = 1, per_page = 10, category = "deal" } = {}
  ) {
    commit(FeeMutationTypes.SetLoadingState, { loading: true });
    try {
      const { data } = await fee.getCollection({ page, per_page, category });

      commit(FeeMutationTypes.SetFeeCollection, { ...data });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(FeeMutationTypes.SetLoadingState, { loading: false });
    }
  },

  async [FeeActionTypes.UpdateFee](
    { dispatch, commit },
    { category = "deal", body = {} } = {}
  ) {
    commit(FeeMutationTypes.SetLoadingState, { loading: true });
    try {
      const { status } = await fee.update({ category, body });
      dispatch(
        RootActionTypes.SetNotification,
        {
          status,
          message: "Success",
        },
        { root: true }
      );
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
      throw error;
    } finally {
      commit(FeeMutationTypes.SetLoadingState, { loading: false });
    }
  },
};

export default actions;
