import { RootActionTypes } from "@/store/root/types";
import { ChatActionTypes, ChatMutationTypes } from "./types";
import { chat } from "@/service";

const actions = {
  async [ChatActionTypes.GetChatCollection](
    { dispatch, commit },
    { did = "" } = {}
  ) {
    try {
      const { data } = await chat.getCollection({ did });

      commit(ChatMutationTypes.SetChatCollection, { ...data });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    }
  },

  async [ChatActionTypes.SendMessage]({ dispatch }, { did = "" } = {}) {
    try {
      await chat.sendMessage({ did });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    }
  },
};

export default actions;
