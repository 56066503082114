var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',_vm._g(_vm._b({},'b-modal',Object.assign({}, {visible: _vm.visible,
    centered: _vm.centered,
    hideFooter: _vm.hideFooter,
    hideHeaderClose: _vm.hideHeaderClose,
    title: _vm.title},
    _vm.style),false),{
    change: function (visible) { return _vm.onChange(visible); },
    hide: function () { return _vm.onClose({ confirm: false }); },
  }),[_c('template',{slot:"default"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var valid = ref.valid;
return [_c('b-form',[_vm._t("default"),_vm._t("action",function(){return [_c('b-btn',_vm._g(_vm._b({staticClass:"rounded"},'b-btn',{
              variant: _vm.style.cancelVariant,
              block: true,
            },false),{
              click: function () { return _vm.onClose({ confirm: false }); },
            }),[(_vm.loading)?[_c('b-spinner',_vm._b({},'b-spinner',{
                  small: true,
                },false))]:[_c('span',{staticClass:"text-capitalize h5"},[_vm._v(" cancel ")])]],2),_c('b-btn',_vm._g(_vm._b({staticClass:"rounded"},'b-btn',{
              disabled: !valid,
              variant: _vm.style.okVariant,
              block: true,
            },false),{
              click: function () { return _vm.onClose({ confirm: true }); },
            }),[(_vm.loading)?[_c('b-spinner',_vm._b({},'b-spinner',{
                  small: true,
                },false))]:[_c('span',{staticClass:"text-capitalize h5"},[_vm._v(" confirm ")])]],2)]})],2)]}}],null,true)})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }