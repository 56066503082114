import { resolve } from "path";

// wid - withdraw id

const TokenSell = ({
  basePath = "token-sale/campaign",
  instance = {},
} = {}) => ({
  // tid trader id
  async getCollection({ path = "index", page = 1, per_page = 10 } = {}) {
    return await instance.get(resolve(basePath, path), {
      params: { page, per_page },
    });
  },

  async get({ path = "show", tid = "" } = {}) {
    return await instance.get(resolve(basePath, path, tid));
  },

  async create({ path = "create", body = {} } = {}) {
    return await instance.post(resolve(basePath, path), body);
  },

  async update({ path = "update", tid = "", body = {} }) {
    return await instance.post(resolve(basePath, path, tid), body);
  },

  async publish({ path = "activate", tid = "" } = {}) {
    return await instance.get(resolve(basePath, path, tid));
  },
});

export default TokenSell;
