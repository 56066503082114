import { resolve } from "path";

// wid - withdraw id

const Dispute = ({ basePath = "disputes", instance = {} } = {}) => ({
  // tid trader id
  async getCollection({ path = "index", page = 1, per_page = 10 } = {}) {
    return await instance.get(resolve(basePath, path), {
      params: { page, per_page },
    });
  },

  async get({ path = "show", did = "" }) {
    return await instance.get(resolve(basePath, path, did));
  },

  async getChatCollection({
    path = "chats/disputes/dispute-chats",
    did = "",
  } = {}) {
    return await instance.get(resolve(path, did));
  },

  async closeDispute({ path = "close-in-favor-maker", did = "" }) {
    return await instance.get(resolve(basePath, path, did));
  },

  //  cid chat id
  async sendMessage({
    path = "chats/disputes",
    cid = "",
    message = { text: "", files: [] },
  } = {}) {
    // TODO replace
    const formData = new FormData();
    if (message.text) {
      formData.append("message", message.text);
    }
    if (message.files.length) {
      Object.entries(message.files).forEach(([index, file]) => {
        formData.append(`files[${index}]`, file);
      });
    }
    return await instance.post(resolve(path, cid, "send-message"), formData);
  },
});

export default Dispute;
