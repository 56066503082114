var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-button',_vm._g(_vm._b({},'b-button',{
    pill: true,
    disabled: _vm.disabled,
    variant: 'dark',
  },false),{
    click: function () { return _vm.$refs.file.click(); },
  }),[_c('input',_vm._g(_vm._b({staticClass:"d-none"},'input',{
      multiple: true,
      accept: 'image/png, image/jpeg',
      type: 'file',
      ref: 'file',
    },false),{
      change: _vm.onChange,
    })),_c('b-icon',_vm._b({},'b-icon',{
      icon: 'b-icon-paperclip',
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }