import { resolve } from "path";

// wid - withdraw id

const Transaction = ({ basePath = "transaction", instance = {} } = {}) => ({
  // tid trader id
  async getCollection({
    path = "index",
    page = 1,
    per_page = 10,
    type = "withdraw",
  } = {}) {
    return await instance.get(resolve(basePath, type, path), {
      params: {
        page,
        per_page,
      },
    });
  },
});

export default Transaction;
