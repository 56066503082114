<template>
  <span> {{ dayjs(date).format(format) }} </span>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    format: {
      type: String,
      default() {
        return "DD, MMM, YYYY hh:mm";
      },
    },
    date: {
      type: String,
      validate(date) {
        return dayjs(date).isValid();
      },
    },
  },
  methods: {
    dayjs,
  },
};
</script>
