const state = {
  users: [],
  user: {},
  loading: false,
  page: 1,
  per_page: 10,
  total: 0,
};

export default state;
