import { WalletMutationTypes } from "@/store/wallet/types";

const mutations = {
  [WalletMutationTypes.SetWalletCollection](state, { data, meta }) {
    state.wallets = data;
    state.page = meta.current_page;
    state.per_page = meta.per_page;
    state.total = meta.total;
  },
  [WalletMutationTypes.SetTransactionCollection](state, { data, meta }) {
    state.transactions = data;
    state.page = meta.current_page;
    state.per_page = meta.per_page;
    state.total = meta.total;
  },
  [WalletMutationTypes.SetLoadingState](state, { loading = false } = {}) {
    state.loading = loading;
  },
};

export default mutations;
