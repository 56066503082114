<template>
  <nav
    v-bind:style="{
      position: 'sticky',
      zIndex: 2,
      top: 0,
    }"
  >
    <b-navbar
      v-bind="{
        type,
        variant,
      }"
    >
      <b-navbar-nav class="mr-2">
        <b-icon
          class="cursor-pointer"
          v-bind="{
            color: 'white',
            fontScale: 2,
            icon: 'b-icon-list',
          }"
          v-on="{
            click: onMenu,
          }"
        >
        </b-icon>
      </b-navbar-nav>

      <b-navbar-brand>Altcoins Hub</b-navbar-brand>

      <b-navbar-nav class="ml-auto">
        <b-btn
          v-bind="{ variant, pill: true }"
          v-on="{
            click: onLogout,
          }"
        >
          <b-icon
            v-bind="{
              icon: 'b-icon-box-arrow-in-right',
            }"
          >
          </b-icon>
        </b-btn>
      </b-navbar-nav>
    </b-navbar>
    <b-progress
      v-bind="{
        value: loading ? 0 : 100,
        variant: 'warning',
        height: '1px',
      }"
    ></b-progress>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import { AuthNamespace, AuthActionTypes } from "@/store/auth/types";
import { navigation } from "@/router/types";
import { mapActions } from "vuex";
import { BIcon, BIconList, BIconBoxArrowInRight } from "bootstrap-vue";

export default {
  name: "MainNavBar",

  components: {
    BIcon,
    // eslint-disable-next-line vue/no-unused-components
    BIconBoxArrowInRight,
    // eslint-disable-next-line vue/no-unused-components
    BIconList,
  },

  props: {
    type: {
      type: String,
      default() {
        return "dark";
      },
      validator(type) {
        return type === "dark";
      },
    },
    variant: {
      type: String,
      default() {
        return "dark";
      },
      validator(variant) {
        return variant === "dark";
      },
    },
    username: {
      type: String,
      default() {
        return "";
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  computed: {
    ...mapState(AuthNamespace, {
      user: "user",
    }),
  },

  methods: {
    ...mapActions(AuthNamespace, [AuthActionTypes.Logout]),

    onMenu() {
      this.$emit("open:menu");
    },

    async onLogout() {
      this.$router.push({ name: navigation.login.name });
      await this[AuthActionTypes.Logout]();
    },
  },
};
</script>
