const FeeNamespace = "fee";
const FeeActionTypes = {
  GetFeeCollection: "GetFeeCollection",
  UpdateFee: "UpdateFee",
};
const FeeMutationTypes = {
  SetFeeCollection: "SetFeeCollection",
  SetLoadingState: "SetLoadingState",
};

export { FeeNamespace, FeeActionTypes, FeeMutationTypes };
