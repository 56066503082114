import { resolve } from "path";

const Fee = ({
  basePath = "commission-limit/commission-limit-operations",
  instance,
} = {}) => ({
  async getCollection({
    path = "show",
    category = "deal",
    page = 1,
    per_page = 10,
  } = {}) {
    return await instance.get(resolve(basePath, path, category), {
      params: {
        page,
        per_page,
      },
    });
  },

  async update({ path = "update", category = "deal", body = {} } = {}) {
    return await instance.post(resolve(basePath, path, category), {
      ...body,
    });
  },
});

export default Fee;
