<template>
  <b-card
    v-bind="{
      noBody: true,
      bgVariant: 'primary',
      textVariant: 'white',
    }"
  >
    <!-- body -->
    <b-row
      class="flex-grow-2"
      v-bind="{
        noGutters: true,
      }"
    >
      <b-col>
        <main-table
          v-bind="{
            loading,
            items,
            page,
            perPage,
            totalRows,
          }"
        >
        </main-table>
      </b-col>
    </b-row>
    <!-- body -->
  </b-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { UserNamespace, UserActionTypes } from "@/store/user/types";
import { MainTable } from "@/components/main";

export default {
  components: {
    MainTable,
  },
  name: "UsersView",
  computed: {
    ...mapState(UserNamespace, {
      users: "users",
      page: "page",
      perPage: "per_page",
      totalRows: "total",
      loading: "loading",
    }),
    items() {
      return this.users.map(
        ({ nickname, email, verification_status, phone }) => ({
          nickname,
          email,
          phone,
          verification_status,
        })
      );
    },
  },

  methods: {
    ...mapActions(UserNamespace, [UserActionTypes.GetUserCollection]),
    navigate() {
      this.$router.push({ name: "user" });
    },
  },

  async mounted() {
    await this[UserActionTypes.GetUserCollection]({
      page: this.page,
      per_page: this.perPage,
    });
  },
};
</script>
