const SellNamespace = "sell";

const SellActionTypes = {
  GetSellCollection: "GetSellCollection",
  GetSell: "GetSell",
  GetCurrencyCollection: "GetCurrencyCollection",
  CreateSell: "CreateSell",
  UpdateSell: "UpdateSell",
  PublishSell: "PublishSell",
};

const SellMutationTypes = {
  SetSellCollection: "SetSellCollection",
  SetCurrencyCollection: "SetCurrencyCollection",
  SetSell: "SetSell",
  SetLoadingState: "SetLoadingState ",
};

const ISell = {
  cryptocurrency_id: "",
  head_rate_id: "",
  price: "",
  total_amount: "",
  min_per_buyer: "",
  max_per_buyer: "",
  crypto_hold_period: "",
  crypto_available_at: new Date(),
  starts_at: new Date(),
  ends_at: new Date(),
  dependent_rate_ids: [],
  page: {
    main_banner: [
      {
        title: "",
        img: "",
        description: "",
      },
    ],
    token_info: [
      {
        category: "",
        blockchain: "",
        contract: "",
        max_supply: "",
        rank: "",
      },
    ],
    company_info: [
      {
        whitepaper: "",
        litepaper: "",
        website: "",
        audit: "",
      },
    ],
    banner: [
      {
        title: "",
        img: "",
      },
    ],
    about: [
      {
        img: "",
        description: "",
      },
    ],
    features: [
      {
        title: "",
        img: "",
        description: "",
      },
    ],
    description: [
      {
        img: "",
        description: "",
      },
    ],
    usability: [
      {
        img: "",
        description: "",
      },
    ],
    economic: [
      {
        img: "",
      },
    ],
    road_map: [
      {
        img: "",
      },
    ],
    partnership: [{ img: "" }],
    social: [{ name: "", link: "" }],
    team: [
      {
        name: "",
        position: "",
        image: "",
        description: "",
        facebook: "",
        instagram: "",
        twitter: "",
        linkedin: "",
      },
    ],
    table: [],
  },
};

export { SellNamespace, SellActionTypes, SellMutationTypes, ISell };
