const WithdrawNamespace = "withdraw";
const WithdrawActionTypes = {
  GetWithdrawCollection: "getWithdrawCollection",
  ConfirmWithdraw: "confirmWithdraw",
  DeclineWithdraw: "declineWithdraw",
};
const WithdrawMutationTypes = {
  SetWithdrawCollection: "setWithdrawCollection",
  SetLoadingState: "SetLoadingState",
};
const WithdrawStatus = {
  confirmed: "confirmed",
  unconfirmed: "unconfirmed",
  declined: "declined",
};

export {
  WithdrawNamespace,
  WithdrawActionTypes,
  WithdrawMutationTypes,
  WithdrawStatus,
};
