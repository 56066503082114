<template>
  <b-sidebar
    v-bind:style="{
      zIndex: 1,
    }"
    v-bind="{
      visible,
      bgVariant: 'dark',
    }"
    v-on="{
      change: (visible) => onChange(visible),
    }"
  >
    <b-nav
      v-model="active"
      v-bind="{
        vertical: true,
      }"
    >
      <template v-for="({ meta, name, visible }, key) in navigation">
        <template v-if="visible">
          <b-nav-item
            v-bind:key="key"
            v-bind="{
              active: name === active,
            }"
            v-on="{
              click: () => navigate(name),
            }"
          >
            <b-icon
              class="ml-2 mr-2"
              v-bind="{
                icon: navigation[name].icon,
              }"
            >
            </b-icon>
            <span class="text-capitalize">
              {{ meta.title }}
            </span>
          </b-nav-item>
        </template>
      </template>
    </b-nav>
  </b-sidebar>
</template>

<script>
import { mapState } from "vuex";
import { AuthNamespace } from "@/store/auth/types";
import {
  BIcon,
  BIconPeopleFill,
  BIconPersonLinesFill,
  BIconPersonBoundingBox,
  BIconWallet,
  BIconHammer,
  BIconAlarm,
  BIconFileEarmarkPost,
  BIconFileEarmarkPostFill,
  BIconCurrencyExchange,
  BIconPieChartFill,
  BIconFileText,
  BIconBarChartSteps,
  BIconConeStriped,
} from "bootstrap-vue";
import { navigation } from "@/router/types";

export default {
  name: "MainNavSideBar",
  components: {
    BIcon,
    // eslint-disable-next-line vue/no-unused-components
    // eslint-disable-next-line vue/no-unused-components
    BIconWallet,
    // eslint-disable-next-line vue/no-unused-components
    BIconHammer,
    // eslint-disable-next-line vue/no-unused-components
    BIconAlarm,
    // eslint-disable-next-line vue/no-unused-components
    BIconPersonLinesFill,
    // eslint-disable-next-line vue/no-unused-components
    BIconPeopleFill,
    // eslint-disable-next-line vue/no-unused-components
    BIconFileEarmarkPost,
    // eslint-disable-next-line vue/no-unused-components
    BIconFileEarmarkPostFill,
    // eslint-disable-next-line vue/no-unused-components
    BIconCurrencyExchange,
    // eslint-disable-next-line vue/no-unused-components
    BIconPersonBoundingBox,
    // eslint-disable-next-line vue/no-unused-components
    BIconPieChartFill,
    // eslint-disable-next-line vue/no-unused-components
    BIconFileText,
    // eslint-disable-next-line vue/no-unused-components
    BIconBarChartSteps,
    // eslint-disable-next-line vue/no-unused-components
    BIconConeStriped,
  },

  model: {
    prop: "visible",
    event: "change",
  },

  props: {
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  computed: {
    ...mapState(AuthNamespace, {
      token: "token",
    }),
    active() {
      return this.$route.name || "";
    },
    navigation() {
      return navigation;
    },
  },

  methods: {
    navigate(name = "") {
      if (this.active === name || this.active === this.$router.name) return;

      this.token
        ? this.$router.push({ name })
        : this.$router.push({ name: navigation.login.name });
    },
    onChange(visible) {
      this.$emit("change", visible);
    },
  },
};
</script>
