import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const fee = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default fee;
