const WalletNamespace = "wallet";
const WalletActionTypes = {
  GetWalletCollection: "GetWalletCollection",
  GetTransactionCollection: "GetWithdrawCollection",
  Withdraw: "Withdraw",
};
const WalletMutationTypes = {
  SetWalletCollection: "SetWalletCollection",
  SetTransactionCollection: "SetTransactionCollection",
  SetLoadingState: "SetLoadingState",
};
const IWithdraw = {
  amount: null,
  address_to: "",
  cryptocurrency_id: "",
  network_id: "",
};

export { WalletNamespace, WalletActionTypes, WalletMutationTypes, IWithdraw };
