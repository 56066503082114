var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',_vm._g(_vm._b({style:({
    zIndex: 1,
  })},'b-sidebar',{
    visible: _vm.visible,
    bgVariant: 'dark',
  },false),{
    change: function (visible) { return _vm.onChange(visible); },
  }),[_c('b-nav',_vm._b({model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},'b-nav',{
      vertical: true,
    },false),[_vm._l((_vm.navigation),function(ref,key){
    var meta = ref.meta;
    var name = ref.name;
    var visible = ref.visible;
return [(visible)?[_c('b-nav-item',_vm._g(_vm._b({key:key},'b-nav-item',{
            active: name === _vm.active,
          },false),{
            click: function () { return _vm.navigate(name); },
          }),[_c('b-icon',_vm._b({staticClass:"ml-2 mr-2"},'b-icon',{
              icon: _vm.navigation[name].icon,
            },false)),_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(meta.title)+" ")])],1)]:_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }