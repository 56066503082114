import { transaction, wallet } from "@/service";
import { WalletActionTypes, WalletMutationTypes } from "@/store/wallet/types";
import { RootActionTypes } from "@/store/root/types";

const actions = {
  async [WalletActionTypes.GetWalletCollection](
    { commit, dispatch },
    { tid = "", page = 1, pare_page = 10 } = {}
  ) {
    commit(WalletMutationTypes.SetLoadingState, { loading: true });

    try {
      const { data } = await wallet.getCollection({ tid, page, pare_page });

      commit(WalletMutationTypes.SetWalletCollection, { ...data });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(WalletMutationTypes.SetLoadingState, { loading: false });
    }
  },

  async [WalletActionTypes.GetTransactionCollection](
    { commit, dispatch },
    { page = 1, per_page = 10, type = "withdraw" } = {}
  ) {
    commit(WalletMutationTypes.SetLoadingState, { loading: true });

    try {
      const { data } = await transaction.getCollection({
        page,
        per_page,
        type,
      });

      commit(WalletMutationTypes.SetTransactionCollection, { ...data });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(WalletMutationTypes.SetLoadingState, { loading: false });
    }
  },

  async [WalletActionTypes.Withdraw](
    { dispatch, commit },
    {
      amount = 0,
      address_to = "",
      cryptocurrency_id = "",
      network_id = "",
    } = {}
  ) {
    commit(WalletMutationTypes.SetLoadingState, { loading: true });

    try {
      await wallet.withdraw({
        amount,
        address_to,
        cryptocurrency_id,
        network_id,
      });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(WalletMutationTypes.SetLoadingState, { loading: false });
    }
  },
};

export default actions;
