import Vue from "vue";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  // localize,
} from "vee-validate";
import { required, email, numeric, double } from "vee-validate/dist/rules";

extend("email", {
  ...email,
  message: "Invalid mail address",
});

extend("required", {
  ...required,
  message: "This field is required",
});

extend("numeric", {
  ...numeric,
  message: "Must be a number",
});

extend("double", {
  ...double,
  message: "Must be a number",
});

extend("balance", {
  validate: (amount, [balance]) => {
    return parseFloat(balance) >= parseFloat(amount);
  },
  message: "Insufficient funds",
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
