<template>
  <b-toast
    v-bind="{
      title: status === 200 ? `Success: ${status}` : `Error: ${status}`,
      visible: index > -1,
      noCloseButton: true,
    }"
    v-on="{
      hide: () => onClose(index),
    }"
  >
    <b-card
      v-bind="{
        noBody: true,
        bgVariant: 'dark',
      }"
    >
      <b-card-text>
        <b-icon
          v-bind="{
            variant: status === 200 ? 'success' : 'danger',
            icon: status === 200 ? 'b-icon-check' : 'b-icon-exclamation',
          }"
        >
        </b-icon>
        {{ message }}
      </b-card-text>
    </b-card>
  </b-toast>
</template>

<script>
import { BToast } from "bootstrap-vue";
import { BIcon, BIconCheck, BIconExclamation } from "bootstrap-vue";

export default BToast.extend({
  name: "MainNotification",
  components: {
    BIcon,
    // eslint-disable-next-line vue/no-unused-components
    BIconCheck,
    // eslint-disable-next-line vue/no-unused-components
    BIconExclamation,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    status: {
      type: [Number, String],
      default() {
        return 400;
      },
    },
    message: {
      type: String,
      default() {
        return "Oops some thing went wrong";
      },
    },
  },
  methods: {
    onClose(index) {
      this.$emit("close:notification", index);
    },
  },
});
</script>

<style lang="scss">
.b-toaster.b-toaster-top-right .b-toaster-slot {
  top: 4em !important;
}
</style>
