const RootNamespace = "root";

const RootActionTypes = {
  SetNotification: "SetNotification",
  SetPendingState: "SetPendingState",
  RemoveNotification: "RemoveNotification",
};

const RootMutationTypes = {
  SetNotification: "SetNotification",
  SetPendingState: "SetPendingState",
  RemoveNotification: "RemoveNotification",
};

export { RootNamespace, RootActionTypes, RootMutationTypes };
