import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const withdraw = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default withdraw;
