import Vue from "vue";
import Vuex from "vuex";
import root from "./root";
import auth from "./auth";
import withdraw from "./withdraw";
import fee from "./fee";
import dispute from "./dispute";
import wallet from "./wallet";
import sell from "./sell";
import chat from "./chat";
import user from "./user";

Vue.use(Vuex);

export default new Vuex.Store({
  ...root,
  modules: {
    auth,
    withdraw,
    fee,
    dispute,
    wallet,
    sell,
    chat,
    user,
  },
});
