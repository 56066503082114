import { resolve } from "path";

const Auth = ({ instance = {} } = {}) => ({
  async login({ path = "login", body = {} } = {}) {
    return await instance.post(resolve(path), body);
  },

  async logout({ path = "logout" } = {}) {
    return await instance.get(resolve(path));
  },

  async refresh({ path = "refresh" } = {}) {
    return await instance.get(resolve(path));
  },
});

export default Auth;
