import { SellMutationTypes } from "./types";
import { SellModel } from "./model";

const mutations = {
  [SellMutationTypes.SetSellCollection](state, { data = [], meta = {} } = {}) {
    state.sells = data;
    state.page = meta.current_page;
    state.per_page = meta.per_page;
    state.total = meta.total;
  },

  [SellMutationTypes.SetCurrencyCollection](
    state,
    { data = [], meta = {} } = {}
  ) {
    state.currencies = data;
    state.page = meta.current_page;
    state.per_page = meta.per_page;
    state.total = meta.total;
  },

  [SellMutationTypes.SetSell](state, { record = {} } = {}) {
    // TODO move to action
    state.sell = SellModel(record);
  },

  [SellMutationTypes.SetLoadingState](state, { loading = false } = {}) {
    state.loading = loading;
  },
};

export default mutations;
