import { $axios } from "./axios";
import {
  Auth,
  Withdraw,
  Fee,
  Wallet,
  Dispute,
  TokenSell,
  Currency,
  Core,
  Chat,
  Transaction,
  User,
} from "@/service/modules";

const auth = Auth({ instance: $axios });
const withdraw = Withdraw({ instance: $axios });
const fee = Fee({ instance: $axios });
const wallet = Wallet({ instance: $axios });
const dispute = Dispute({ instance: $axios });
const sell = TokenSell({ instance: $axios });
const currency = Currency({ instance: $axios });
const core = Core({ instance: $axios });
const chat = Chat({ instance: $axios });
const transaction = Transaction({ instance: $axios });
const user = User({ instance: $axios });

export {
  auth,
  withdraw,
  fee,
  wallet,
  dispute,
  sell,
  currency,
  core,
  chat,
  transaction,
  user,
};
