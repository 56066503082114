var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',_vm._b({},'b-card',{
    bgVariant: 'primary',
    noBody: true,
  },false),[_c('b-card-header',_vm._b({staticClass:"text-white"},'b-card-header',{
      headerBgVariant: 'primary',
    },false),[_vm._t("filters")],2),_c('b-card-body',[_c('b-table',_vm._b({scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('p',{staticClass:"h6 text-center"},[_vm._v(_vm._s(_vm.loadingText))])]},proxy:true},{key:"head(actions)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(address)",fn:function(ref){
    var value = ref.value;
return [_c('base-clipboard-copy',_vm._b({},'base-clipboard-copy',{ text: value },false))]}},{key:"cell(txid)",fn:function(ref){
    var value = ref.value;
return [_c('base-clipboard-copy',_vm._b({},'base-clipboard-copy',{ text: value },false))]}},{key:"cell(address_to)",fn:function(ref){
    var value = ref.value;
return [_c('base-clipboard-copy',_vm._b({},'base-clipboard-copy',{ text: value },false))]}},{key:"cell(address_from)",fn:function(ref){
    var value = ref.value;
return [_c('base-clipboard-copy',_vm._b({},'base-clipboard-copy',{ text: value },false))]}},{key:"cell(taker)",fn:function(ref){
    var value = ref.value;
return [_c('base-clipboard-copy',_vm._b({},'base-clipboard-copy',{ cut: false, text: value.id, value: value.name },false))]}},{key:"cell(maker)",fn:function(ref){
    var value = ref.value;
return [_c('base-clipboard-copy',_vm._b({},'base-clipboard-copy',{ cut: false, text: value.id, value: value.name },false))]}},{key:"cell(cryptocurrency)",fn:function(ref){
    var value = ref.value;
return [_c('b-card-text',{staticClass:"d-flex align-items-center"},[_c('b-img',_vm._b({staticClass:"mr-2"},'b-img',{
              height: 16,
              width: 16,
              src: value.icon,
            },false)),_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(value.code)+" ")])],1)]}},{key:"cell(network)",fn:function(ref){
            var value = ref.value;
return [_c('b-card-text',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v(" "+_vm._s(value.name)+" ")])])]}},{key:"cell(fiat)",fn:function(ref){
            var value = ref.value;
return [_c('b-card-text',{staticClass:"d-flex align-items-center"},[_c('b-img',_vm._b({staticClass:"mr-2"},'b-img',{
              height: 16,
              width: 16,
              src: value.icon,
            },false)),_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(value.code)+" ")])],1)]}},{key:"cell(status)",fn:function(ref){
            var value = ref.value;
return [_c('b-card-text',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"text-capitalize",class:{
              'text-danger': value === 'disputed',
              'text-success': value === 'completed',
              'text-warning': value === 'declined',
            }},[_vm._v(" "+_vm._s(value)+" ")])])]}},{key:"cell(starts_at)",fn:function(ref){
            var value = ref.value;
return [_c('base-date',_vm._b({},'base-date',{
            date: value,
          },false))]}},{key:"cell(ends_at)",fn:function(ref){
          var value = ref.value;
return [_c('base-date',_vm._b({},'base-date',{
            date: value,
          },false))]}},{key:"cell(created_at)",fn:function(ref){
          var value = ref.value;
return [_c('base-date',_vm._b({},'base-date',{
            date: value,
          },false))]}},{key:"cell(updated_at)",fn:function(ref){
          var value = ref.value;
return [_c('base-date',_vm._b({},'base-date',{
            date: value,
          },false))]}},{key:"cell(fee_cryptocurrency)",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(value[0].cryptocurrency.code)+" ")]}},{key:"cell(actions)",fn:function(ref){
          var item = ref.item;
          var value = ref.value;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_vm._l((value),function(ref,key){
          var link = ref.link;
          var icon = ref.icon;
          var text = ref.text;
          var variant = ref.variant;
          var disabled = ref.disabled;
return [(link)?[_c('b-link',_vm._b({key:link},'b-link',{
                  target: '_blank',
                  href: (link + "/" + (item.id)),
                },false),[_c('b-button',_vm._b({},'b-button',{
                    pill: true,
                    variant: 'dark',
                    disabled: disabled ? disabled : false,
                  },false),[_c('b-icon',_vm._b({},'b-icon',{
                      variant: variant,
                      icon: icon,
                    },false))],1)],1)]:(icon)?[_c('b-button',_vm._g(_vm._b({key:icon},'b-button',{
                  pill: true,
                  variant: 'dark',
                  disabled: disabled ? disabled : false,
                },false),{
                  click: function () { return _vm.onAction(key, item); },
                }),[_c('b-icon',_vm._b({},'b-icon',{
                    variant: variant,
                    icon: icon,
                  },false))],1)]:[_c('b-button',_vm._g(_vm._b({key:text,staticClass:"mr-2"},'b-button',{
                  variant: variant,
                  size: 'sm',
                  disabled: disabled ? disabled : false,
                },false),{
                  click: function () { return _vm.onAction(key, item); },
                }),[_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(text)+" ")])])]]})],2)]}}])},'b-table',{
        tableVariant: 'dark',
        items: _vm.items,
        fields: _vm.fields,
        busy: _vm.loading,
        showEmpty: true,
        responsive: true,
      },false)),(_vm.totalRows)?[_c('b-pagination',_vm._g(_vm._b({model:{value:(_vm.curr),callback:function ($$v) {_vm.curr=$$v},expression:"curr"}},'b-pagination',{
          align: 'center',
          hideGotoEndButtons: true,
          totalRows: _vm.totalRows,
          perPage: _vm.perPage,
        },false),{
          change: _vm.onPageChange,
        }))]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }