<template>
  <b-button
    v-bind="{
      pill: true,
      disabled,
      variant: 'dark',
    }"
    v-on="{
      click: () => $refs.file.click(),
    }"
  >
    <input
      class="d-none"
      v-bind="{
        multiple: true,
        accept: 'image/png, image/jpeg',
        type: 'file',
        ref: 'file',
      }"
      v-on="{
        change: onChange,
      }"
    />
    <b-icon
      v-bind="{
        icon: 'b-icon-paperclip',
      }"
    >
    </b-icon>
  </b-button>
</template>

<script>
import { BIcon, BIconPaperclip } from "bootstrap-vue";
export default {
  name: "BaseFileLoad",
  components: {
    BIcon,
    // eslint-disable-next-line vue/no-unused-components
    BIconPaperclip,
  },

  props: {
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  methods: {
    onChange() {
      this.$emit("load:file", this.$refs.file.files);
    },
  },
};
</script>
