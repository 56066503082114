import axios from "axios";
import retry from "axios-retry";
import { config } from "./config";

const $axios = axios.create(config);

retry(axios, {
  retries: 3,
});

import { navigation } from "@/router/types";
import { get, set, clear } from "local-storage";

$axios.interceptors.request.use((request) => {
  // request config base on url
  const { url } = request;

  Object.assign(request.headers.common, {
    authorization: get("altcoin_access_token"),
  });

  if (url.includes(navigation.login.path)) {
    // TODO delete this
    Object.assign(request, { baseURL: process.env.VUE_APP_AUTH_URL });

    return request;
  }

  if (url.includes(navigation.logout.path)) {
    Object.assign(request, { baseURL: process.env.VUE_APP_AUTH_URL });

    return request;
  }

  if (url.includes(navigation.logout.path)) {
    Object.assign(request, { baseURL: process.env.VUE_APP_AUTH_URL });

    return request;
  }

  Object.assign(request, { baseURL: process.env.VUE_APP_ADMIN_URL });

  return request;
});

$axios.interceptors.response.use(
  (response) => {
    const { url } = response.config;

    if (url.includes(navigation.login.path)) {
      const { data } = response;
      const { token_type, access_token, record } = data;

      set("altcoin_access_token", `${token_type} ${access_token}`);
      set("altcoin_user", record);

      return response;
    }

    if (url.includes(navigation.logout.path)) {
      clear();
      return response;
    }

    return response;
  },
  async (error) => {
    const status = error.response?.status || error.code;
    const message = error.response?.message || error.message;

    if (status === 401) {
      clear();
    }

    throw { status, message };
  }
);

export { $axios };
