import { UserMutationTypes } from "./types";

const mutations = {
  [UserMutationTypes.SetUserCollection](state, { data = [], meta = {} } = {}) {
    state.users = data;
    state.page = meta.current_page;
    state.per_page = meta.per_page;
    state.total = meta.total;
  },

  [UserMutationTypes.SetUser](state, { record = {} } = {}) {
    state.user = record;
  },

  [UserMutationTypes.SetLoadingState](state, { loading = false } = {}) {
    state.loading = loading;
  },
};

export default mutations;
