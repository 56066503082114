import { RootActionTypes } from "@/store/root/types";
import { SellActionTypes, SellMutationTypes } from "./types";
import { sell, currency } from "@/service";

const actions = {
  async [SellActionTypes.GetSellCollection](
    { dispatch, commit },
    { page = 1, per_page = 10 } = {}
  ) {
    commit(SellMutationTypes.SetLoadingState, { loading: true });

    try {
      const { data } = await sell.getCollection({ page, per_page });

      commit(SellMutationTypes.SetSellCollection, { ...data });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(SellMutationTypes.SetLoadingState, { loading: false });
    }
  },

  async [SellActionTypes.GetSell]({ dispatch, commit }, { tid }) {
    commit(SellMutationTypes.SetLoadingState, { loading: true });

    try {
      const { data } = await sell.get({ tid });

      commit(SellMutationTypes.SetSell, { ...data });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(SellMutationTypes.SetLoadingState, { loading: false });
    }
  },

  async [SellActionTypes.CreateSell]({ commit, dispatch }, { body = {} } = {}) {
    commit(SellMutationTypes.SetLoadingState, { loading: true });

    try {
      const { status } = await sell.create({ body });

      dispatch(
        RootActionTypes.SetNotification,
        {
          status: status,
          message: "Success",
        },
        { root: true }
      );
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(SellMutationTypes.SetLoadingState, { loading: false });
    }
  },

  async [SellActionTypes.UpdateSell](
    { commit, dispatch },
    { tid = "", body = {} } = {}
  ) {
    commit(SellMutationTypes.SetLoadingState, { loading: true });

    try {
      const { data } = await sell.update({ tid, body });
      const { status } = data;

      dispatch(
        RootActionTypes.SetNotification,
        {
          status: status,
          message: "Success",
        },
        { root: true }
      );
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(SellMutationTypes.SetLoadingState, { loading: false });
    }
  },

  async [SellActionTypes.GetCurrencyCollection](
    { dispatch, commit },
    { page = 1, per_page = 100 } = {}
  ) {
    try {
      const { data } = await currency.getCollection({ page, per_page });
      commit(SellMutationTypes.SetCurrencyCollection, { ...data });
    } catch (error) {
      dispatch(
        RootActionTypes.SetNotification,
        {
          status: error.status,
          message: error.message,
        },
        { root: true }
      );
      throw error;
    }
  },

  async [SellActionTypes.PublishSell]({ dispatch }, { tid = "" } = {}) {
    try {
      await sell.publish({ tid });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    }
  },
};

export default actions;
