import { ISell } from "./types";
// Need update on back end
const SellModel = (sell) => {
  return {
    cryptocurrency_id: sell.cryptocurrency.id || "",
    main_image_id: sell.main_image_id || "",
    head_rate_id: sell.head_pair_rate.id || "",
    price: sell.head_pair_rate.value || 0,
    total_amount: sell.total_amount || 0,
    sold_amount: sell.sold_amount || 0,
    min_per_buyer: sell.min_per_buyer || 0,
    max_per_buyer: sell.max_per_buyer || 0,
    crypto_hold_period: sell.crypto_hold_period || 0,
    crypto_available_at: sell.crypto_available_at,
    starts_at: sell.starts_at,
    ends_at: sell.ends_at,
    dependent_rate_ids: sell.dependent_pair_rates.length
      ? sell.dependent_pair_rates.map(({ cryptocurrency_id }) => {
          return cryptocurrency_id;
        })
      : [],
    title: sell.title,
    description: sell.description,
    preview: sell.preview,
    page: {
      main_banner:
        sell.campaign_page?.data?.main_banner || ISell.page.main_banner,
      token_info: sell?.campaign_page.data?.token_info || ISell.page.token_info,
      company_info:
        sell?.campaign_page.data?.company_info || ISell.page.company_info,
      banner: sell?.campaign_page.data?.banner || ISell.page.banner,
      about: sell?.campaign_page.data?.about || ISell.page.about,
      features: sell?.campaign_page.data?.features || ISell.page.features,
      description:
        sell?.campaign_page.data?.description || ISell.page.description,
      usability: sell?.campaign_page.data?.usability || ISell.page.usability,
      economic: sell?.campaign_page.data?.economic || ISell.page.economic,
      social: sell?.campaign_page.data?.social || ISell.page.social,
      partnership:
        sell?.campaign_page.data?.partnership || ISell.page.partnership,
      road_map: sell?.campaign_page.data?.road_map || ISell.page.road_map,
      team: sell?.campaign_page.data?.team || ISell.page.team,
      table: sell?.campaign_page.data?.table || ISell.page.table,
    },
  };
};

export { SellModel };
