const DisputeNamespace = "dispute";

const DisputeActionTypes = {
  GetDisputeCollection: "GetDisputeCollection",
  GetDispute: "GetDispute",
  GetDisputeChat: "GetDisputeChat",
  CloseDispute: "CloseDispute",
  SendMessage: "SendMessage",
  ClearState: "ClearState",
};

const DisputeMutationTypes = {
  SetDisputeCollection: "SetDisputeCollection",
  SetDispute: "SetDispute",
  SetDisputeChat: "SetDisputeChat",
  SetLoadingState: "SetLoadingState",
  ClearState: "ClearState",
};

const IState = {
  disputes: [],
  dispute: {},
  chats: {},
  loading: false,
  page: 1,
  per_page: 10,
  total: 0,
};

export { DisputeNamespace, DisputeActionTypes, DisputeMutationTypes, IState };
