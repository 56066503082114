<template>
  <validation-observer v-slot="{ valid }">
    <b-form
      v-on="{
        submit: (event) => onSubmit({ event, valid }),
      }"
    >
      <b-card
        class="rounded"
        v-bind="{
          noBody: true,
          bgVariant: 'transparent',
          textVariant: 'white',
        }"
      >
        <slot
          name="default"
          v-bind="{
            valid,
          }"
        >
        </slot>
        <slot
          name="action"
          v-bind="{
            valid,
          }"
        >
        </slot>
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationObserver,
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    onSubmit({ event, valid }) {
      event.preventDefault();
      if (valid) {
        this.$emit("submit");
      }
    },
    onCancel() {
      this.$emit("cancel");
    },
  },
};
</script>
