import { FeeMutationTypes } from "./types";

const mutations = {
  [FeeMutationTypes.SetFeeCollection](
    state,
    { data = [], current_page = 1, per_page = 10, total = 0 } = {}
  ) {
    state.fees = data;
    state.page = current_page;
    state.per_page = per_page;
    state.total = total;
  },
  [FeeMutationTypes.SetLoadingState](state, { loading } = {}) {
    state.loading = loading;
  },
};

export default mutations;
