import { AuthMutationTypes } from "./types";

const mutations = {
  [AuthMutationTypes.SetUser](state, { record }) {
    state.user = record;
  },
  [AuthMutationTypes.SetToken](
    state,
    { token_type = "", access_token = "" } = {}
  ) {
    state.token = `${token_type} ${access_token}`;
  },
  [AuthMutationTypes.SetLoadingState](state, { loading = false } = {}) {
    state.loading = loading;
  },
};

export default mutations;
