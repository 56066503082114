import { WithdrawMutationTypes } from "./types";

const mutations = {
  [WithdrawMutationTypes.SetWithdrawCollection](
    state,
    { data = [], meta = {} } = {}
  ) {
    state.withdraws = data;
    state.page = meta.current_page;
    state.per_page = meta.per_page;
    state.total = meta.total;
  },
  [WithdrawMutationTypes.SetLoadingState](state, { loading = false } = {}) {
    state.loading = loading;
  },
};

export default mutations;
