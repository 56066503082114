const navigation = Object.freeze({
  login: {
    name: "login",
    path: "/login",
    visible: false,
  },
  logout: {
    name: "logout",
    path: "/logout",
    visible: false,
  },
  users: {
    name: "users",
    path: "/users",
    visible: true,
    icon: "b-icon-people-fill",
    meta: {
      title: "users",
    },
  },
  user: {
    name: "user",
    path: "/user",
    visible: false,
    meta: {
      title: "user",
    },
  },
  roles: {
    name: "roles",
    path: "/roles",
    visible: true,
    icon: "b-icon-person-lines-fill",
    meta: {
      title: "roles",
    },
  },
  orders: {
    name: "orders",
    path: "/orders",
    visible: true,
    icon: "b-icon-file-earmark-post",
    meta: {
      title: "P2P orders",
    },
  },
  offers: {
    name: "offers",
    path: "/offers",
    visible: true,
    icon: "b-icon-file-earmark-post-fill",
    meta: {
      title: "P2P offers",
    },
  },
  withdraws: {
    name: "withdraws",
    path: "/withdraws",
    visible: true,
    icon: "b-icon-alarm",
    meta: {
      title: "pending withdraws",
    },
  },
  disputes: {
    name: "disputes",
    path: "/disputes",
    visible: true,
    icon: "b-icon-hammer",
    meta: {
      title: "disputes",
    },
  },
  dispute: {
    name: "dispute",
    path: "/disputes/:id",
    visible: false,
    meta: {
      title: "dispute",
    },
  },
  pays: {
    name: "pays",
    path: "/pays",
    visible: true,
    icon: "b-icon-pie-chart-fill",
    meta: {
      title: "ACH Pay",
    },
  },
  fees: {
    name: "fees",
    path: "/fees",
    visible: true,
    icon: "b-icon-bar-chart-steps",
    meta: {
      title: "fees",
    },
  },
  logs: {
    name: "logs",
    path: "/logs",
    visible: true,
    icon: "b-icon-file-text",
    meta: {
      title: "logs",
    },
  },
  wallets: {
    name: "wallets",
    path: "/wallets/",
    visible: true,
    icon: "b-icon-wallet",
    meta: {
      title: "wallets",
    },
  },
  verifications: {
    name: "verifications",
    path: "/verifications",
    visible: true,
    icon: "b-icon-person-bounding-box",
    meta: {
      title: "KYS",
    },
  },
  tokens: {
    name: "tokens",
    path: "/tokens",
    visible: true,
    icon: "b-icon-currency-exchange",
    meta: {
      title: "Token Sell",
    },
  },
  token: {
    create: {
      name: "token-create",
      path: "/tokens/token",
      visible: false,
      meta: {
        title: "Token Create",
      },
    },
    edit: {
      name: "token-edit",
      path: "/tokens/token/:id",
      visible: false,
      meta: {
        title: "Token Edit",
      },
    },
  },
});

export { navigation };
