var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',_vm._b({staticClass:"vh-100",style:({
    minWidth: '360px',
  })},'b-container',{
    fluid: true,
  },false),[(_vm.$route.name === 'login')?[_c('router-view')]:[_c('main-nav-bar',_vm._g({},{
        'open:menu': _vm.onMenu,
      })),_c('b-row',_vm._b({},'b-row',{
        noGutters: true,
      },false),[_c('main-nav-side-bar',{model:{value:(_vm.menu.visible),callback:function ($$v) {_vm.$set(_vm.menu, "visible", $$v)},expression:"menu.visible"}}),_c('b-col',[_c('router-view')],1)],1)],(_vm.$notifications.length)?[_vm._l((_vm.$notifications),function(ref,index){
      var status = ref.status;
      var message = ref.message;
return [_c('main-notification',_vm._g(_vm._b({key:index,style:({
          zIndex: 3,
        })},'main-notification',{
          index: index,
          status: status,
          message: message,
        },false),{
          'close:notification': _vm.onClose,
        }))]})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }