const ChatNamespace = "chat";

const ChatActionTypes = {
  GetChatCollection: "getChatCollection",
  SendMessage: "sendMessage",
};

const ChatMutationTypes = {
  SetChatCollection: "setChatCollection",
};

export { ChatNamespace, ChatActionTypes, ChatMutationTypes };
