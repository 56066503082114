import { withdraw } from "@/service";
import {
  WithdrawActionTypes,
  WithdrawMutationTypes,
} from "@/store/withdraw/types";
import { RootActionTypes } from "@/store/root/types";

const actions = {
  async [WithdrawActionTypes.GetWithdrawCollection](
    { commit, dispatch },
    { page = 1, per_page = 10 } = {}
  ) {
    commit(WithdrawMutationTypes.SetLoadingState, { loading: true });

    try {
      const { data } = await withdraw.getCollection({ page, per_page });

      commit(WithdrawMutationTypes.SetWithdrawCollection, { ...data });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
      throw error;
    } finally {
      commit(WithdrawMutationTypes.SetLoadingState, { loading: false });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [WithdrawActionTypes.ConfirmWithdraw]({ dispatch }, { wid = "" } = {}) {
    try {
      const { status } = await withdraw.confirm({ wid });
      dispatch(
        RootActionTypes.SetNotification,
        {
          status,
          message: "Success",
        },
        { root: true }
      );
      dispatch(WithdrawActionTypes.GetWithdrawCollection);
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    }
  },

  async [WithdrawActionTypes.DeclineWithdraw](
    { dispatch },
    { wid = "", decline_reason = "" } = {}
  ) {
    try {
      const { status } = await withdraw.decline({ wid, decline_reason });

      dispatch(
        RootActionTypes.SetNotification,
        {
          status,
          message: "Success",
        },
        { root: true }
      );
      dispatch(WithdrawActionTypes.GetWithdrawCollection);
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    }
  },
};

export default actions;
