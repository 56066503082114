var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('b-form',_vm._g({},{
      submit: function (event) { return _vm.onSubmit({ event: event, valid: valid }); },
    }),[_c('b-card',_vm._b({staticClass:"rounded"},'b-card',{
        noBody: true,
        bgVariant: 'transparent',
        textVariant: 'white',
      },false),[_vm._t("default",null,null,{
          valid: valid,
        }),_vm._t("action",null,null,{
          valid: valid,
        })],2)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }