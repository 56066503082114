<template>
  <b-card
    v-bind="{
      bgVariant: 'primary',
      noBody: true,
    }"
  >
    <b-card-header
      class="text-white"
      v-bind="{
        headerBgVariant: 'primary',
      }"
    >
      <slot name="filters"> </slot>
    </b-card-header>

    <b-card-body>
      <b-table
        v-bind="{
          tableVariant: 'dark',
          items,
          fields,
          busy: loading,
          showEmpty: true,
          responsive: true,
        }"
      >
        <template #table-busy>
          <p class="h6 text-center">{{ loadingText }}</p>
        </template>

        <template #head(actions)>
          <span> </span>
        </template>

        <template #cell(address)="{ value }">
          <base-clipboard-copy v-bind="{ text: value }"> </base-clipboard-copy>
        </template>

        <template #cell(txid)="{ value }">
          <base-clipboard-copy v-bind="{ text: value }"> </base-clipboard-copy>
        </template>

        <template #cell(address_to)="{ value }">
          <base-clipboard-copy v-bind="{ text: value }"> </base-clipboard-copy>
        </template>

        <template #cell(address_from)="{ value }">
          <base-clipboard-copy v-bind="{ text: value }"> </base-clipboard-copy>
        </template>

        <template #cell(taker)="{ value }">
          <base-clipboard-copy
            v-bind="{ cut: false, text: value.id, value: value.name }"
          >
          </base-clipboard-copy>
        </template>

        <template #cell(maker)="{ value }">
          <base-clipboard-copy
            v-bind="{ cut: false, text: value.id, value: value.name }"
          >
          </base-clipboard-copy>
        </template>

        <template #cell(cryptocurrency)="{ value }">
          <b-card-text class="d-flex align-items-center">
            <b-img
              class="mr-2"
              v-bind="{
                height: 16,
                width: 16,
                src: value.icon,
              }"
            >
            </b-img>
            <span class="text-uppercase"> {{ value.code }} </span>
          </b-card-text>
        </template>

        <template #cell(network)="{ value }">
          <b-card-text class="d-flex align-items-center">
            <span> {{ value.name }} </span>
          </b-card-text>
        </template>

        <template #cell(fiat)="{ value }">
          <b-card-text class="d-flex align-items-center">
            <b-img
              class="mr-2"
              v-bind="{
                height: 16,
                width: 16,
                src: value.icon,
              }"
            >
            </b-img>
            <span class="text-uppercase"> {{ value.code }} </span>
          </b-card-text>
        </template>

        <template #cell(status)="{ value }">
          <b-card-text class="d-flex align-items-center">
            <span
              class="text-capitalize"
              v-bind:class="{
                'text-danger': value === 'disputed',
                'text-success': value === 'completed',
                'text-warning': value === 'declined',
              }"
            >
              {{ value }}
            </span>
          </b-card-text>
        </template>

        <template #cell(starts_at)="{ value }">
          <base-date
            v-bind="{
              date: value,
            }"
          >
          </base-date>
        </template>

        <template #cell(ends_at)="{ value }">
          <base-date
            v-bind="{
              date: value,
            }"
          >
          </base-date>
        </template>

        <template #cell(created_at)="{ value }">
          <base-date
            v-bind="{
              date: value,
            }"
          >
          </base-date>
        </template>

        <template #cell(updated_at)="{ value }">
          <base-date
            v-bind="{
              date: value,
            }"
          >
          </base-date>
        </template>

        <template #cell(fee_cryptocurrency)="{ value }">
          {{ value[0].cryptocurrency.code }}
        </template>

        <template #cell(actions)="{ item, value }">
          <div class="d-flex justify-content-end">
            <template
              v-for="({ link, icon, text, variant, disabled }, key) of value"
            >
              <template v-if="link">
                <b-link
                  v-bind:key="link"
                  v-bind="{
                    target: '_blank',
                    href: `${link}/${item.id}`,
                  }"
                >
                  <b-button
                    v-bind="{
                      pill: true,
                      variant: 'dark',
                      disabled: disabled ? disabled : false,
                    }"
                  >
                    <b-icon
                      v-bind="{
                        variant,
                        icon,
                      }"
                    >
                    </b-icon>
                  </b-button>
                </b-link>
              </template>
              <template v-else-if="icon">
                <b-button
                  v-bind:key="icon"
                  v-bind="{
                    pill: true,
                    variant: 'dark',
                    disabled: disabled ? disabled : false,
                  }"
                  v-on="{
                    click: () => onAction(key, item),
                  }"
                >
                  <b-icon
                    v-bind="{
                      variant,
                      icon,
                    }"
                  >
                  </b-icon>
                </b-button>
              </template>
              <template v-else>
                <b-button
                  class="mr-2"
                  v-bind:key="text"
                  v-bind="{
                    variant,
                    size: 'sm',
                    disabled: disabled ? disabled : false,
                  }"
                  v-on="{
                    click: () => onAction(key, item),
                  }"
                >
                  <span class="text-capitalize">
                    {{ text }}
                  </span>
                </b-button>
              </template>
            </template>
          </div>
        </template>
      </b-table>

      <template v-if="totalRows">
        <b-pagination
          v-model="curr"
          v-bind="{
            align: 'center',
            hideGotoEndButtons: true,
            totalRows,
            perPage,
          }"
          v-on="{
            change: onPageChange,
          }"
        >
        </b-pagination>
      </template>
    </b-card-body>
  </b-card>
</template>

<script>
import { BaseDate, BaseClipboardCopy } from "@/components/base";
import {
  BIcon,
  BIconHammer,
  BIconEye,
  BIconLock,
  BIconUnlock,
  BIconFlag,
  BIconPencil,
  BIconPauseCircle,
  BIconXCircle,
} from "bootstrap-vue";

export default {
  name: "MainTable",
  components: {
    BIcon,
    BaseDate,
    BaseClipboardCopy,
    // eslint-disable-next-line vue/no-unused-components
    BIconPauseCircle,
    // eslint-disable-next-line vue/no-unused-components
    BIconXCircle,
    // eslint-disable-next-line vue/no-unused-components
    BIconHammer,
    // eslint-disable-next-line vue/no-unused-components
    BIconEye,
    // eslint-disable-next-line vue/no-unused-components
    BIconLock,
    // eslint-disable-next-line vue/no-unused-components
    BIconUnlock,
    // eslint-disable-next-line vue/no-unused-components
    BIconFlag,
    // eslint-disable-next-line vue/no-unused-components
    BIconPencil,
  },
  props: {
    page: {
      type: Number,
      default() {
        return 0;
      },
    },
    totalRows: {
      type: Number,
      default() {
        return 0;
      },
    },
    perPage: {
      type: [String, Number],
      default() {
        return "5";
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    loadingText: {
      type: String,
      default() {
        return "Loading...";
      },
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      curr: this.page || 1,
    };
  },
  computed: {
    fields() {
      return this.items.length
        ? Object.keys(this.items[0])
            .map((key) => ({
              key,
              label: () => key.charAt(0).toUpperCase(),
              tdClass:
                key === "id" ||
                key === "limit_min_id" ||
                key === "limit_max_id" ||
                key === "commission_maker_id" ||
                key === "commission_taker_id" ||
                key === "commission_receiver_id" ||
                key === "commission_sender_id" ||
                key === "commission_deposit_id" ||
                key === "commission_withdraw_id" ||
                key === "limit_24h_id"
                  ? "d-none"
                  : "table-row",
              thClass:
                key === "id" ||
                key === "limit_min_id" ||
                key === "limit_max_id" ||
                key === "commission_maker_id" ||
                key === "commission_taker_id" ||
                key === "commission_receiver_id" ||
                key === "commission_sender_id" ||
                key === "commission_deposit_id" ||
                key === "commission_withdraw_id" ||
                key === "limit_24h_id"
                  ? "d-none"
                  : "table-row",
            }))
            .filter(({ key }) => key !== "slug")
        : [];
    },
  },
  methods: {
    onPageChange(page) {
      this.$emit("change:page", page);
    },
    onAction(name, item) {
      this.$emit(name, item);
    },
  },
};
</script>
