import { resolve } from "path";

const Currency = ({
  basePath = "token-sale/cryptocurrency",
  instance = {},
} = {}) => ({
  // tid trader id
  async getCollection({ path = "index", page = 1, limit = 15 } = {}) {
    return await instance.get(resolve(basePath, path), {
      params: { page, limit },
    });
  },
});

export default Currency;
