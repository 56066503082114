import { DisputeMutationTypes } from "./types";

const mutations = {
  [DisputeMutationTypes.SetDisputeCollection](
    state,
    { data = [], meta = {} } = {}
  ) {
    state.disputes = data;
    state.page = meta.current_page;
    state.per_page = meta.per_page;
    state.total = meta.total;
  },

  [DisputeMutationTypes.SetDispute](state, { record } = {}) {
    state.dispute = record;
  },

  [DisputeMutationTypes.SetDisputeChat](state, { data } = {}) {
    state.chats = data;
  },

  [DisputeMutationTypes.SetLoadingState](state, { loading = false } = {}) {
    state.loading = loading;
  },

  [DisputeMutationTypes.ClearState](
    state,
    {
      disputes = [],
      dispute = {},
      chats = {},
      loading = false,
      page = 1,
      per_page = 10,
      total = 0,
    } = {}
  ) {
    state.disputes = disputes;
    state.dispute = dispute;
    state.chats = chats;
    state.loading = loading;
    state.page = page;
    state.per_page = per_page;
    state.total = total;
  },
};

export default mutations;
