import { auth } from "@/service";
import { AuthActionTypes, AuthMutationTypes } from "@/store/auth/types";
import { RootActionTypes } from "@/store/root/types";

const actions = {
  async [AuthActionTypes.Login](
    { commit, dispatch },
    { email = "", password = "" } = {}
  ) {
    commit(AuthMutationTypes.SetLoadingState, { loading: true });

    try {
      const { data, status } = await auth.login({
        body: {
          email,
          password,
        },
      });

      commit(AuthMutationTypes.SetUser, { ...data });
      commit(AuthMutationTypes.SetToken, { ...data });

      dispatch(
        RootActionTypes.SetNotification,
        { status, message: "Success" },
        { root: true }
      );
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(AuthMutationTypes.SetLoadingState, { loading: false });
    }
  },

  async [AuthActionTypes.Logout]({ dispatch }) {
    try {
      const { status } = await auth.logout();
      dispatch(
        RootActionTypes.SetNotification,
        { status, message: "Success" },
        { root: true }
      );
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    }
  },
};

export default actions;
