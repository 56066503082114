import { resolve } from "path";

const User = ({ basePath = "auth/users", instance = {} }) => ({
  async getCollection({ path = "index", page = 1, per_page = 10 }) {
    return await instance.get(resolve(basePath, path), {
      params: {
        page,
        per_page,
      },
    });
  },

  async get(path = "show", id = "") {
    return await instance.get(resolve(basePath, path, id));
  },

  async create({
    path = "create",
    body = {
      name: "",
      last_name: "",
      email: "",
      password: "",
    },
  } = {}) {
    return await instance.post(resolve(basePath, path), {
      body,
    });
  },

  async update(
    path = "update",
    body = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    }
  ) {
    return await instance.put(resolve(basePath, path), {
      body,
    });
  },

  async delete(path = "delete", id) {
    return await instance.delete(resolve(basePath, path, id));
  },
});

export default User;
