import { RootActionTypes } from "@/store/root/types";
import { UserActionTypes, UserMutationTypes } from "./types";
import { user } from "@/service";

const actions = {
  async [UserActionTypes.GetUserCollection](
    { dispatch, commit },
    { page = 1, per_page = 10 } = {}
  ) {
    commit(UserMutationTypes.SetLoadingState, { loading: true });

    try {
      const { data } = await user.getCollection({ page, per_page });

      commit(UserMutationTypes.SetUserCollection, { ...data });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(UserMutationTypes.SetLoadingState, { loading: false });
    }
  },

  async [UserActionTypes.GetUser]({ dispatch, commit }, { tid }) {
    commit(UserMutationTypes.SetLoadingState, { loading: true });

    try {
      const { data } = await user.get({ tid });

      commit(UserMutationTypes.SetUser, { ...data });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(UserMutationTypes.SetLoadingState, { loading: false });
    }
  },

  async [UserActionTypes.CreateUser]({ commit, dispatch }, { body = {} } = {}) {
    commit(UserMutationTypes.SetLoadingState, { loading: true });

    try {
      const { status } = await user.create({ body });

      dispatch(
        RootActionTypes.SetNotification,
        { status, message: "Success" },
        { root: true }
      );
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(UserMutationTypes.SetLoadingState, { loading: false });
    }
  },

  async [UserActionTypes.UpdateUser](
    { commit, dispatch },
    { tid = "", body = {} } = {}
  ) {
    commit(UserMutationTypes.SetLoadingState, { loading: true });

    try {
      const { data } = await user.update({ tid, body });
      const { status } = data;

      dispatch(
        RootActionTypes.SetNotification,
        { status, message: "Success" },
        { root: true }
      );
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(UserMutationTypes.SetLoadingState, { loading: false });
    }
  },
};

export default actions;
