import { resolve } from "path";

// wid - withdraw id

const Chat = ({ basePath = "chats/disputes", instance = {} } = {}) => ({
  async getCollection({ path = "dispute-chats", did = "" } = {}) {
    return await instance.get(resolve(basePath, path, did));
  },
  //  cid chat id
  async sendMessage({ path = "send-message", cid = "", message = "" } = {}) {
    return await instance.post(resolve(basePath, cid, path), {
      message,
    });
  },
});

export default Chat;
