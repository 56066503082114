<template>
  <b-container
    class="vh-100"
    v-bind:style="{
      minWidth: '360px',
    }"
    v-bind="{
      fluid: true,
    }"
  >
    <template v-if="$route.name === 'login'">
      <router-view />
    </template>

    <template v-else>
      <main-nav-bar
        v-on="{
          'open:menu': onMenu,
        }"
      >
      </main-nav-bar>

      <b-row
        v-bind="{
          noGutters: true,
        }"
      >
        <main-nav-side-bar v-model="menu.visible"> </main-nav-side-bar>
        <b-col>
          <router-view />
        </b-col>
      </b-row>
    </template>

    <template v-if="$notifications.length">
      <template v-for="({ status, message }, index) in $notifications">
        <main-notification
          v-bind:key="index"
          v-bind:style="{
            zIndex: 3,
          }"
          v-bind="{
            index,
            status,
            message,
          }"
          v-on="{
            'close:notification': onClose,
          }"
        >
        </main-notification>
      </template>
    </template>
  </b-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { AuthNamespace } from "@/store/auth/types";
import { RootActionTypes } from "@/store/root/types";
import { navigation } from "@/router/types";
import {
  MainNavBar,
  MainNavSideBar,
  MainNotification,
} from "@/components/main";

export default {
  components: {
    MainNavBar,
    MainNavSideBar,
    MainNotification,
  },

  data() {
    return {
      menu: {
        visible: false,
      },
    };
  },

  computed: {
    ...mapState(AuthNamespace, {
      token: "token",
    }),
    ...mapState({ $notifications: "notifications" }),
  },

  methods: {
    ...mapActions([RootActionTypes.RemoveNotification]),

    onMenu() {
      this.menu.visible = !this.menu.visible;
    },

    onClose(index) {
      this[RootActionTypes.RemoveNotification](index);
    },
  },

  async created() {
    if (!this.token) {
      this.$router.push({ name: navigation.login.name });
    }
  },
};
</script>

<style lang="scss">
// @import "@/style/styles";
</style>
