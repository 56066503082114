import { RootActionTypes } from "@/store/root/types";
import { DisputeActionTypes, DisputeMutationTypes } from "./types";
import { dispute } from "@/service";

const actions = {
  async [DisputeActionTypes.GetDisputeCollection](
    { dispatch, commit },
    { page = 1, per_page = 10 } = {}
  ) {
    commit(DisputeMutationTypes.SetLoadingState, { loading: true });

    try {
      const { data } = await dispute.getCollection({ page, per_page });

      commit(DisputeMutationTypes.SetDisputeCollection, { ...data });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(DisputeMutationTypes.SetLoadingState, { loading: false });
    }
  },

  async [DisputeActionTypes.GetDispute](
    { dispatch, commit },
    { did = "" } = {}
  ) {
    commit(DisputeMutationTypes.SetLoadingState, { loading: true });

    try {
      const { data } = await dispute.get({ did });
      dispatch(DisputeActionTypes.GetDisputeChat, { did });
      commit(DisputeMutationTypes.SetDispute, { ...data });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    } finally {
      commit(DisputeMutationTypes.SetLoadingState, { loading: false });
    }
  },

  async [DisputeActionTypes.CloseDispute](
    { dispatch },
    { did = "", side = "maker" }
  ) {
    try {
      const { status } = await dispute.closeDispute({
        did,
        path:
          side === "maker" ? "close-in-favor-maker" : "close-in-favor-taker",
      });
      dispatch(
        RootActionTypes.SetNotification,
        {
          status: status,
          message: `Success`,
        },
        { root: true }
      );
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    }
  },

  async [DisputeActionTypes.GetDisputeChat](
    { dispatch, commit },
    { did = "" } = {}
  ) {
    try {
      const { data } = await dispute.getChatCollection({ did });
      commit(DisputeMutationTypes.SetDisputeChat, { ...data });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    }
  },

  async [DisputeActionTypes.SendMessage](
    { dispatch },
    { did = "", cid = "", message = { text: "", file: "" } } = {}
  ) {
    try {
      await dispute.sendMessage({ cid, message });
      dispatch(DisputeActionTypes.GetDisputeChat, { did });
    } catch (error) {
      dispatch(RootActionTypes.SetNotification, { ...error }, { root: true });
    }
  },

  [DisputeActionTypes.ClearState]({ commit }) {
    commit(DisputeMutationTypes.ClearState);
  },
};

export default actions;
