import { resolve } from "path";

// wid - withdraw id

const Wallet = ({ basePath = "balances/admin", instance = {} } = {}) => ({
  // tid trader id
  async getCollection({ path = "index", page = 1, per_page = 10 } = {}) {
    return await instance.get(resolve(basePath, path), {
      params: { page, per_page },
    });
  },

  async withdraw({
    path = "create",
    amount = 0,
    address_to = "",
    cryptocurrency_id = "",
    network_id = "",
  } = {}) {
    return await instance.post(resolve("withdraw", path), {
      amount,
      address_to,
      cryptocurrency_id,
      network_id,
    });
  },
});

export default Wallet;
