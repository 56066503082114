import { resolve } from "path";

const Core = ({ basePath = "core/files", instance = {} } = {}) => ({
  async getCollection({ path = "index", page = 1, limit = 10 } = {}) {
    return await instance.get(resolve(basePath, path), {
      params: { page, limit },
    });
  },
});

export default Core;
